import { JumboTabs } from 'antd-mobile';
import { useContext, useEffect, useState } from 'react';
import { JobContext } from '../../../context/jobContext';
import { IJob } from '../../../utils/types';
import StaffCompleted from './completed/staffCompleted';
import StaffDeclinedJobCard from './declined/declinedJobCard';
import StaffInprogressJobs from './inProgress/staffInporgress';
import StaffScheduleJobs from './schedule/staffScheduleJobs';
import StaffCancelled from './cancelled/staffCancelled';

interface staffJobTypesProps {
    activeTabKey: string;
    date: Date;
};

const StaffJobTypes = ({ activeTabKey, date }: staffJobTypesProps) => {
    const [activeKey, setActiveKey] = useState(activeTabKey);
    const { scheduledJobs, inprogressJob, completedJobs, cancelledJobs, declinedJobs } = useContext(JobContext);
    const [filteredInprogressJobs, setFilteredInprogressJobs] = useState<IJob[] | undefined>([]);
    const [filteredCompletedJobs, setFilteredCompletedJobs] = useState<IJob[] | undefined>([]);
    const [filteredCancelledJobs, setFilteredCancelledJobs] = useState<IJob[] | undefined>([]);
    const [filteredDeclinedJobs, setFilteredDeclinedJobs] = useState<IJob[] | undefined>([]);

    const handleTabChange = (key: string) => {
        setActiveKey(key);
    };

    const convertDateFormat = (inputDate: Date) => {
        const date = inputDate.toLocaleDateString('en-GB');
        const parts = date?.split('/');
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];
        const convertedDate = `${year}-${month}-${day}`;
        return convertedDate;
    };

    useEffect(() => {
        const filteredInpJobs = inprogressJob?.filter((job) => { return job?.date?.slice(0, 10) === convertDateFormat(date); });
        setFilteredInprogressJobs(filteredInpJobs);
    }, [date, inprogressJob]);

    useEffect(() => {
        const filteredComJobs = completedJobs?.filter((job) => { return job?.date?.slice(0, 10) === convertDateFormat(date); });
        setFilteredCompletedJobs(filteredComJobs);
    }, [date, completedJobs]);

    useEffect(() => {
        const filteredCanJobs = cancelledJobs?.filter((job) => { return job.date?.slice(0, 10) === convertDateFormat(date); });
        setFilteredCancelledJobs(filteredCanJobs);
    }, [date, cancelledJobs]);

    useEffect(() => {
        const filteredDecJobs = declinedJobs?.filter((job) => { return job?.date?.slice(0, 10) === convertDateFormat(date); });
        setFilteredDeclinedJobs(filteredDecJobs);
    }, [date, declinedJobs]);


    return (
        <JumboTabs
            className="smk-jobtypes"
            activeKey={ activeKey }
            onChange={ handleTabChange }
        >
            <JumboTabs.Tab title='Scheduled' description='' key='schedule' className="smk-jumbotypes-item">
                { scheduledJobs?.map((job) => {
                    if (job.date.slice(0, 10) == convertDateFormat(date)) {
                        return <StaffScheduleJobs dataset={ job } key={ job.jobId } />;
                    }
                }) }
            </JumboTabs.Tab>

            { filteredInprogressJobs && filteredInprogressJobs?.length > 0 && (
                <JumboTabs.Tab title="In-Progress" description="" key="inprogress" className="smk-jumbotypes-item">
                    { filteredInprogressJobs.map((job) => {
                        return (
                            <StaffInprogressJobs dataset={ job } key={ job.jobId } />
                        );
                    }) }
                </JumboTabs.Tab>
            ) }

            { filteredCompletedJobs && filteredCompletedJobs?.length > 0 && (
                <JumboTabs.Tab title="Completed" description="" key="complete" className="smk-jumbotypes-item">
                    { filteredCompletedJobs.map((job) => {
                        return (
                            <StaffCompleted dataset={ job } key={ job.jobId } />
                        );
                    }) }
                </JumboTabs.Tab>
            ) }

            { filteredCancelledJobs && filteredCancelledJobs?.length > 0 && (
                <JumboTabs.Tab title="Cancelled" description="" key="cancel" className="smk-jumbotypes-item">
                    { filteredCancelledJobs.map((job) => {
                        return (
                            <StaffCancelled dataset={ job } key={ job.jobId } />
                        );
                    }) }
                </JumboTabs.Tab>
            ) }

            { filteredDeclinedJobs && filteredDeclinedJobs?.length > 0 && (
                <JumboTabs.Tab title="Declined" description="" key="decline" className="smk-jumbotypes-item">
                    { filteredDeclinedJobs.map((job) => {
                        return (
                            <StaffDeclinedJobCard dataset={ job } key={ job.jobId } />
                        );
                    }) }
                </JumboTabs.Tab>
            ) }

            {/* <JumboTabs.Tab title='Completed' description='' key='complete'>
                { completedJobs.map((job) => {
                    if (job.date === date.toLocaleDateString('en-GB')) {
                        return <StaffCompleted dataset={ job } key={ job.id } />;
                    } else {
                        return null;
                    }
                    
                })}
            </JumboTabs.Tab> */}
            {/* { (inprogressJob?.jobId &&  && */ }
            {/* <JumboTabs.Tab title='In-progress' description='' key='inprogress' className="smk-jumbotypes-item">
                
            </JumboTabs.Tab> */}
            {/* } */ }
            {/* <JumboTabs.Tab title='Completed' description='' key='complete'>
                {completedJobs.map((job) => {
                    if(job.date === date.toLocaleDateString('en-GB')) {
                        return <StaffCompleted dataset={job} key={job.id} />;
                    } else {
                        return null;
                    }
                }) }
            </JumboTabs.Tab> */}
        </JumboTabs>
    );
};

export default StaffJobTypes;