import React from 'react';

interface notificationProps {
    type: string;
};

const NotificationStatus = ({ type }: notificationProps) => {
    let status = '';
    switch (type) {
    case 'Completed':
        status = 'completed';
        break;
    case 'Scheduled':
        status = 'scheduled';
        break;
    case 'In-progress':
        status = 'progress';
        break;
    default:
        status = 'cancelled';
        break;
    }
    return (
        <div className={`smk-status smk-status_${status}`}>
            {type}
        </div>
    );
};

export default NotificationStatus;