import { HashRouter, Route, Routes } from 'react-router-dom';
import './assets/style/main.scss';
import ForgetPasswordEmail from './components/login/components/fogetpasswordEmail';
import ResetPassword from './components/login/components/resetPassword';
import VerifyCode from './components/login/components/verifyCode';
import Login from './components/login/login';
import StaffCanclledView from './components/staff/components/cancelled/staffCanclledView';
import StaffCompltedView from './components/staff/components/completed/staffCompletedView';
import InProgressCheckIn from './components/staff/components/inProgress/inProgressView';
import NotificationView from './components/staff/components/notifications/notificationView';
import StaffProfile from './components/staff/components/profile/staffProfile';
import StaffScheduleView from './components/staff/components/schedule/staffScheduleView';
import StaffSettings from './components/staff/components/staffSettings';
import StaffHome from './components/staff/staffHome';
import StaffNotification from './components/unused/staffNotifications';
import { JobProvider } from './context/jobContext';

function App () {

    // const [firstLogin, setfirstLogin] = useState(true);
    // const { state } = useAuthContext();
    // const { user } = state;

    // useEffect(() => {
    //     const firstTime = localStorage.getItem('firstTime');
    //     if (firstTime == 'true') {
    //         setfirstLogin(false);
    //     }
    // }, []);

    return (
        <JobProvider>
            <HashRouter>
                <Routes>
                    <Route path="/" element={ <Login /> } />
                    {/* <Route path="/" element={ firstLogin ? <StartPage /> : <Login /> } /> */ }
                    {/* <Route path="/start" element={ <Splash /> } /> */ }
                    {/* <Route path="/signup" element={ <StaffHome /> } /> */ }
                    {/* <Route path="/signup" element={ !user ? <Signup /> : <Navigate to='/home' /> } /> */ }
                    <Route path="/login" element={ <Login /> } />
                    <Route path="/forgetpassword" element={ <ForgetPasswordEmail /> } />
                    <Route path="/verifycode" element={ <VerifyCode /> } />
                    <Route path="/resetpassword" element={ <ResetPassword /> } />
                    <Route path="/home" element={ <StaffHome /> } />
                    <Route path="/settings" element={ <StaffSettings /> } />
                    <Route path="/staff/profile" element={ <StaffProfile /> } />
                    <Route path="/notifications" element={ <StaffNotification /> } />
                    <Route path="/staff/notifications/view" element={ <NotificationView /> } />
                    <Route path="/staff/schedule/view" element={ <StaffScheduleView /> } />
                    <Route path="/staff/inprogress/view" element={ <InProgressCheckIn /> } />
                    <Route path="/staff/complete/view" element={ <StaffCompltedView /> } />
                    <Route path="/staff/cancel/view" element={ <StaffCanclledView /> } />
                </Routes>
            </HashRouter>
        </JobProvider>
    );
}

export default App;
