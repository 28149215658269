import { AutoCenter, Button, Form, Grid, Image, Modal, NumberKeyboard, PasscodeInput } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import Images from '../../../assets/images/image';
import Footer from '../../footer/footer';
import axios from 'axios';
import ModalSuccess from '../../staff/components/modalSuccess';
import { useRef, useState } from 'react';
import { baseUrl } from '../../../Constants';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const inputRef = useRef<HTMLInputElement>(null!);
    const [value, setValue] = useState('');
    const handleChange = (val: any) => {
        setValue(val);
        if (val.length === 4) {
            inputRef.current.blur();
        }
    };
    const handleBack = () => {
        navigate('/');
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleResetPassword = async (values: any) => {
        try {
            const userData = {
                email: localStorage.getItem('email'),
                password: values.pin,
            };
            const response = await axios.post(`${baseUrl}/users/reset`, userData);
            if (response.status === 200) {
                navigate('/login');
                Modal.alert({
                    content: <ModalSuccess message={'Your password reset successfully.'} />,
                    closeOnMaskClick: true,
                    confirmText: 'Ok',
                    className: 'smk-modal smk-modal_alert',
                });
            } else {
                console.log('Error');
            }
        } catch (error) {
            console.log('Validation error:', error);
            navigate('/login');
        }
    };
    return (
        <div>
            <Grid columns={ 1 } gap={ 10 }>
                <div className="smk-splashimagecontainer smk-splashimagecontainer_logo">
                    <Image src={ Images.resetpassword } style={ { height: '80%', width: 'auto', objectFit: 'cover', padding: '10px' } } />
                </div>
                <Grid.Item style={ { overflow: 'scroll', paddingBottom: '30px', paddingLeft: '30px', paddingRight: '30px' } }>
                    <div>
                        <span className="smk-titlelogin">Reset Pin</span>
                    </div>
                    <Form
                        className="smk-form"
                        layout="vertical"
                        form={ form }
                        onFinish={ handleResetPassword }
                    >
                        <Form.Item
                            className="smk-form-input"
                            name='pin'
                            label='Enter Pin'
                            // rules={ [{ required: true, type: 'number', message: 'Please input your pin here!' }] }
                        >
                            <PasscodeInput className="smk-inputpin"
                                onChange={handleChange}
                                ref={inputRef}
                                value={value}
                                seperated style={ { marginBottom: '15px' } }
                                length={ 4 } plain keyboard={ <NumberKeyboard /> } />
                        </Form.Item>
                        <Form.Item
                            className="smk-form-input"
                            name='confirmPin'
                            label='Confirm Pin'
                            // rules={ [{ required: true, message: 'Please confirm your pin here!' }] }
                        >
                            <PasscodeInput className="smk-inputpin"
                                onChange={handleChange}
                                ref={inputRef}
                                value={value}
                                seperated style={ { marginBottom: '15px' } }
                                length={ 4 } plain keyboard={ <NumberKeyboard /> } />
                        </Form.Item>
                        <AutoCenter className="smk-submitbuttondiv smk-submitbuttondiv-forgot">
                            <Button type="submit" className="smk-button">
                                Submit
                            </Button>
                        </AutoCenter>
                    </Form>
                    <AutoCenter>
                        <div className="smk-schedul-row">
                            <p className="smk-linktext">Back to</p>
                            <Button className="smk-button-link" onClick={ () => { return handleBack(); } }>
                                Home page
                            </Button>
                        </div>
                    </AutoCenter>
                </Grid.Item>
                <Grid.Item>
                    <Footer />
                </Grid.Item>
            </Grid>
        </div>
    );
};

export default ResetPassword;