import { Button, Form, Modal, TextArea } from 'antd-mobile';
import { useContext } from 'react';
import ModalSuccess from '../modalSuccess';
import { JobContext } from '../../../../context/jobContext';

type unableFormProps = {
    jobId: number,
    onSuccess: () => void
}
const UnableForm = (props: unableFormProps) => {
    const [form] = Form.useForm();
    const { jobId, onSuccess } = props;
    const { addDeclinedJob } = useContext(JobContext);
    
    const handleSubmit = async (values: any) => {
        const { reason } = values;
        const rejectDeatils = {
            rejectedReason: reason,
        };
        addDeclinedJob(jobId, rejectDeatils);
        await Modal.alert({
            content: <ModalSuccess message={ 'You have rejected job successfully.' } />,
            closeOnMaskClick: true,
            confirmText: 'Ok',
            className: 'smk-modal smk-modal_alert',
        });
        onSuccess();

    };


    return (
        <div className="smk-unablediv">
            <Form
                className="smk-form"
                layout="vertical"
                form={ form }
                onFinish={ handleSubmit }
            >
                <Form.Item
                    name='reason'
                    label="Reason"
                    rules={ [{ type: 'string', required: true, message: 'Please input your excuse here!' }] }
                    className="smk-form-input"
                >
                    <TextArea
                        className="smk-textarea"
                        placeholder='Enter your reason'
                    />
                </Form.Item>
                <div
                    className="smk-editbutton-container smk-editbutton-container-incident"
                    style={ { paddingTop: '10px', justifyContent: 'flex-end' } }
                >
                    <Button className="smk-button smk-button-reject" type="submit">
                        Submit
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default UnableForm;