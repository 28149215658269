import { useLocation } from 'react-router-dom';
import Header from '../header/header';
import DateWeek from './components/date';
import StaffJobTypes from './components/staffJobTypes';

const StaffHome = () => {
    const location = useLocation();
    const key = location.state?.activeKey || 'schedule';
    const date = location.state?.clickedDate || new Date();
    return (
        <div className="smk-maindiv">
            <div>
                <Header />
            </div>
            <div className="smk-detailsdiv" style={ { paddingTop: '100px', height: '88vh' } }>
                <div className="smk-staffmain">
                    {/* <div className="smk-monthdiv" >
                        <Month />
                    </div> */}
                    <DateWeek />
                    <StaffJobTypes activeTabKey={ key } date={ date } />
                </div>
            </div>
        </div>
    );
};

export default StaffHome;