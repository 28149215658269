import { AutoCenter, Button, Form, Grid, Image, Modal, NumberKeyboard, PasscodeInput } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import Images from '../../../assets/images/image';
import Footer from '../../footer/footer';
import axios from 'axios';
import ModalSuccess from '../../staff/components/modalSuccess';
import { useRef, useState } from 'react';
import { baseUrl } from '../../../Constants';

const VerifyCode = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const inputRef = useRef<HTMLInputElement>(null!);
    const [value, setValue] = useState('');
    const handleChange = (val: any) => {
        setValue(val);
        if (val.length === 4) {
            inputRef.current.blur();
        }
    };
    const handleRefresh = () => {
        navigate('/verifycode');
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleVerifyForm = async (values: any) => {
        try {
            const userData = {
                email: localStorage.getItem('email'),
                otp: values.otp,
            };
            const response = await axios.post(`${baseUrl}/users/verify`, userData);
            if (response.status == 200) {
                Modal.alert({
                    content: <ModalSuccess message={'Your account verified successfully.'} />,
                    closeOnMaskClick: true,
                    confirmText: 'Ok',
                    className: 'smk-modal smk-modal_alert',
                });
                navigate('/resetpassword');
            }
        } catch (error) {
            console.log('Validation error:', error);
        }
    };
    return (
        <div className="smk-splashscreencontainer" style={ { height: '95vh' } }>
            <Grid columns={ 1 } gap={ 10 }>
                <div className="smk-splashimagecontainer">
                    <Image src={ Images.verifycode } style={ { height: '80%', width: 'auto', objectFit: 'cover', padding: '10px' } } />
                </div>
                <Grid.Item
                    style={ { overflow: 'scroll', paddingTop: '20px', paddingBottom: '30px', paddingLeft: '30px', paddingRight: '30px' } }
                >
                    <div>
                        <span className="smk-titlelogin">Verify Account</span>
                    </div>
                    <Form
                        className="smk-form"
                        layout="vertical"
                        form={ form }
                        onFinish={ handleVerifyForm }
                    >
                        <Form.Item
                            className="smk-form-input"
                            name='otp'
                            label='Verification code'
                            rules={ [{ required: true, message: 'Please input your verification here!' }] }
                        >
                            <PasscodeInput className="smk-inputpin"
                                onChange={handleChange}
                                ref={inputRef}
                                value={value}
                                seperated style={ { marginBottom: '15px' } }
                                length={ 4 } plain keyboard={ <NumberKeyboard /> } />
                        </Form.Item>
                        <AutoCenter className="smk-submitbuttondiv smk-submitbuttondiv-forgot">
                            <Button type="submit" className="smk-button" >
                                Submit
                            </Button>
                        </AutoCenter>
                    </Form>
                    <AutoCenter>
                        <div className="smk-formheader smk-formheader-verify">
                            <p className="smk-linktext">
                                Haven&apos;t received PIN?
                            </p>
                            <Button className="smk-button-link" onClick={ () => { return handleRefresh(); } } type="submit">
                                Send again
                            </Button>
                        </div>
                    </AutoCenter>
                </Grid.Item>
                <Grid.Item>
                    <Footer />
                </Grid.Item>
            </Grid>
        </div>
    );
};

export default VerifyCode;