import axios from 'axios';
import { baseUrl } from '../Constants';

const apiClient = axios.create({
    baseURL: baseUrl,
});

// Add an interceptor to include the access token in the header
apiClient.interceptors.request.use(async (config) => {
    const userData = localStorage.getItem('userData');
    if (userData) {
        const user = JSON.parse(userData);
        try {
            config.headers.Authorization = `Bearer ${user.token}`;
        } catch (error) {
            console.error('Error getting user session:', error);
        }
    }
    return config;
});

export default apiClient;
