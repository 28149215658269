import { Collapse, Form, Grid, Image, Space } from 'antd-mobile';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Images from '../../../../assets/images/image';
import { JobContext } from '../../../../context/jobContext';
import { ITask } from '../../../../utils/types';
import Header from '../../../header/header';
// import Navbar from '../../../navbar/navbar';

const StaffCompltedView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dataset = location.state.dataset;
    const { getOneJob, job } = useContext(JobContext);
    const activeKey = 'complete';
    const goBack = () => {
        navigate('/home', { state: { activeKey } });
    };

    useEffect(() => {
        console.log('Job Id', dataset.jobId);
        getOneJob(dataset.jobId);
    }, [dataset, getOneJob]);

    return (
        <div className="smk-maindiv">
            <div>
                <Header />
            </div>
            <div className="smk-detailsdiv" style={ { paddingTop: '100px', height: '76vh' } }>
                <div className="smk-staffmain">
                    <div className="smk-gobackcontainer" onClick={ goBack }>
                        <Image src={ Images.leftArrow } width={ '6%' } /><span className="smk-goacktitle">Go back</span>
                    </div>
                    <div className="smk-titlecontainer">
                        <span className="smk-profilename">Completed Job</span>
                    </div>
                    <div>
                        <div className="smk-scheduleitemcheck">
                            <div className="smk-schedul-details">
                                <Grid columns={ 2 }>
                                    <Grid.Item>
                                        <p><span className="smk-scheduleitemname">Date: </span></p>
                                        <p><span className="smk-scheduleitemname">Start time: </span></p>
                                        <p><span className="smk-scheduleitemname">Checked-in time: </span></p>
                                        <p><span className="smk-scheduleitemname">Checked-out time: </span></p>
                                        <p><span className="smk-scheduleitemname">End time: </span></p>
                                        <p><span className="smk-scheduleitemname">Frequency: </span></p>
                                    </Grid.Item>
                                    <Grid.Item>
                                        <p className="smk-detailstag">
                                            <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                { dataset.date.slice(0, 10) }</span></p>
                                        <p className="smk-detailstag">
                                            <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                { moment(dataset?.startTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</span></p>
                                        <p className="smk-detailstag">
                                            <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                { moment(dataset?.checkInTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</span></p>
                                        <p className="smk-detailstag">
                                            <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                { moment(dataset?.checkOutTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</span></p>
                                        <p className="smk-detailstag">
                                            <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                { moment(dataset?.endTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</span></p>
                                        <p className="smk-detailstag">
                                            <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                { dataset?.frequency }</span></p>
                                    </Grid.Item>
                                </Grid>
                                <Collapse className="smk-collapse_task">
                                    <Collapse.Panel key='1'
                                        style={ { paddingLeft: '0px' } }
                                        title={
                                            <div style={ { width: '100%' } }>
                                                <span className="smk-scheduleitemname">Tasks list: </span>
                                            </div>
                                        }>
                                        <div>
                                            { job && <Space direction='vertical' block>
                                                { job.task?.map((task: ITask) => {
                                                    return <div
                                                        className="smk-tasklist-checkbox 
                                                                            smk-tasklist-checkbox-inprogress"
                                                        key={ task.taskId }>
                                                        <div className="smk-taskdiv">
                                                            <Collapse className="smk-collapse_notify">
                                                                <Collapse.Panel key={ task.taskId.toString() }
                                                                    className="smk-collapse_panel"
                                                                    title={
                                                                        <div className="smk-collapsediv">
                                                                            <div className="smk-broomcontainer">
                                                                                <Image src={ Images.alarm } />
                                                                            </div>
                                                                            <p className="smk-collapsetitle">
                                                                                { task.task }
                                                                            </p>
                                                                        </div> }
                                                                >
                                                                    <div className="smk-tasktitle">
                                                                        { task.task }
                                                                    </div>
                                                                    <div>
                                                                        { task.outcome }
                                                                    </div>
                                                                    <div className="smk-descriptiondiv">
                                                                        <div className="smk-desleft">
                                                                            { task.area }
                                                                        </div>
                                                                    </div>
                                                                </Collapse.Panel>
                                                            </Collapse>
                                                        </div>
                                                    </div>;
                                                }) }
                                            </Space> }
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    <Form>
                        <Form.Item
                            name='photo'
                            label="Before photos"
                            className="smk-form-input"
                            style={ { width: '88%' } }
                        >
                            <div style={ { display: 'flex', flexDirection: 'row' } }>
                                { dataset?.beforePhotos?.split(', ')?.map((imageUrl: string, index: number) => {
                                    return (
                                        <Image width={ '50px' } key={ index } src={ imageUrl } alt={ `Image ${index}` } />
                                    );
                                }) }
                            </div>
                        </Form.Item>
                        <Form.Item
                            name='photo'
                            label="After photos"
                            className="smk-form-input"
                            style={ { width: '88%' } }
                        >
                            <div style={ { display: 'flex', flexDirection: 'row' } }>
                                { dataset?.afterPhotos?.split(', ')?.map((imageUrl: string, index: number) => {
                                    return (
                                        <Image width={ '50px' } key={ index } src={ imageUrl } alt={ `Image ${index}` } />
                                    );
                                }) }
                            </div>
                        </Form.Item>
                    </Form>
                    { dataset?.checkInNote && <div className="smk-incident">
                        <p><span className="smk-scheduleitemname">CheckIn Note: </span> { dataset?.checkInNote } </p>
                    </div>
                    }
                    { dataset?.checkOutNote && <div className="smk-incident">
                        <p><span className="smk-scheduleitemname">CheckOut Note: </span> { dataset?.checkOutNote } </p>
                    </div>
                    }
                    { dataset?.incidentType && <div className="smk-incident">
                        <p><span className="smk-scheduleitemname">Incident Type: </span> { dataset?.incidentType } </p>
                        <p><span className="smk-scheduleitemname">Description:</span> { dataset?.incidentDescription } </p>
                        <p></p>
                    </div> }
                </div>
            </div>
            {/* <div>
                <Navbar />
            </div> */}
        </div>
    );
};

export default StaffCompltedView;