import { AutoCenter, Grid } from 'antd-mobile';
import React from 'react';

const Footer = () => {
    return (
        <div>
            <Grid columns={1} gap={10}>
                <AutoCenter>
                    <div className="smk-footerdiv">
                        <p>2023 © Powered by <span className="smk-footerspan">iManage</span></p>
                    </div>
                </AutoCenter>
            </Grid>
        </div>
    );
};

export default Footer;