import React from 'react';
import Header from '../../header/header';
// import Navbar from '../../navbar/navbar';
import { Modal, Switch } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import ModalContent from './modalContent';
import ModalSuccess from './modalSuccess';
import ModalError from './modalError';

const StaffSettings = () => {
    const navigate = useNavigate();
    const handleSchedule = () => {
        Modal.confirm({
            content: <ModalContent message='Are you sure want to change schdeule notitfication?' />,
            confirmText: 'Yes',
            cancelText: 'No',
            className: 'smk-modal',
        });
    };
    const handleInprogress = () => {
        Modal.confirm({
            content: <ModalContent message='Are you sure want to change in-progress notitfication?' />,
            confirmText: 'Yes',
            cancelText: 'No',
            className: 'smk-modal',
        });
    };
    const handleComplete = () => {
        Modal.confirm({
            content: <ModalContent message='Are you sure want to change complete notitfication?' />,
            confirmText: 'Yes',
            cancelText: 'No',
            className: 'smk-modal',
        });
    };
    const handleCancel = () => {
        Modal.confirm({
            content: <ModalContent message='Are you sure want to change cancel notitfication?' />,
            confirmText: 'Yes',
            cancelText: 'No',
            className: 'smk-modal',
        });
    };
    const handleConfirm = () => {
        navigate('/');
        Modal.alert({
            content: <ModalSuccess message={'You have deleted your account successfully.'} />,
            closeOnMaskClick: true,
            confirmText: 'Ok',
            className: 'smk-modal smk-modal_alert',
        });
    };
    const handleDelete = () => {
        Modal.confirm({
            content: <ModalError message='Are you sure want to delete?' />,
            confirmText: 'Yes',
            cancelText: 'No',
            className: 'smk-modal',
            onConfirm: handleConfirm,
        });
    };
    return (
        <div className="smk-maindiv">
            <div>
                <Header />
            </div>
            <div className="smk-detailsdiv" style={{ paddingTop: '100px', height: '78vh' }}>
                <div className="smk-staffmain">
                    <div className="smk-titlecontainer">
                        <span className="smk-profilename">Settings</span>
                    </div>
                    <div className="smk-titlecontainer">
                        <span className="smk-profilename smk-profilename_pin">Change notification settings</span>
                    </div>
                    <div className="smk-switchmaindiv">
                        <div className="smk-switchcontainer">
                            <div>Scheduled job notifications</div>
                            <div><Switch onChange={handleSchedule}></Switch></div>
                        </div>
                        <div className="smk-switchcontainer">
                            <div>In-progress job notifications</div>
                            <div><Switch onChange={handleInprogress}></Switch></div>
                        </div>
                        <div className="smk-switchcontainer">
                            <div>Completed job notifications</div>
                            <div><Switch onChange={handleComplete}></Switch></div>
                        </div>
                        <div className="smk-switchcontainer">
                            <div>Cancelled job notifications</div>
                            <div><Switch onChange={handleCancel}></Switch></div>
                        </div>
                    </div>
                    <div className="smk-titlecontainer smk-titlecontainer_delete">
                        <span
                            className="smk-profilename smk-profilename_pin smk-profilename_pin_delete"
                            onClick={handleDelete}
                        >
                            Delete my account
                        </span>
                    </div>
                </div>
            </div>
            {/* <div>
                <Navbar />
            </div> */}
        </div>
    );
};

export default StaffSettings;