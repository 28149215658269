import React from 'react';
import Header from '../header/header';
// import Navbar from '../navbar/navbar';

const StaffNotification = () => {
    return (
        <div className="smk-maindiv">
            <div>
                <Header />
            </div>
            <div className="smk-detailsdiv" style={{ paddingTop: '100px', height: '76vh' }}>
                <div className="smk-staffmain">
                    <div className="smk-titlecontainer">
                        <span className="smk-profilename">Notifications</span>
                    </div>
                </div>
            </div>
            {/* <div>
                <Navbar />
            </div> */}
        </div>
    );
};

export default StaffNotification;