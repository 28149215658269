import { Grid } from 'antd-mobile';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { IJob } from '../../../../utils/types';

interface ScheduleDataset {
    dataset: IJob;
}
const StaffCompleted = ({ dataset }: ScheduleDataset) => {
    const navigate = useNavigate();
    const handleView = () => {
        navigate('/staff/complete/view', { state: { dataset } });
    };
    return (
        <div className="smk-schedul-item" onClick={ handleView }>
            <Grid columns={ 1 } gap={ 10 }>
                <Grid.Item>
                    <div className="smk-scheduledisplaydiv">
                        {/* <div className="smk-scheduledisplaydivleft">
                            <Image src={Images.Logo_image} width='70%' />
                        </div> */}
                        <div className="smk-scheduledisplaydivright">
                            <div className="smk-schedul-row">
                                <div className="smk-schedul-details">
                                    <Grid columns={ 1 }>
                                        <Grid.Item>
                                            <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                                                <div style={ { textAlign: 'left', display: 'block' } }>
                                                    <div><span className="smk-scheduleitemname">Client: </span></div>
                                                    <div><span className="smk-scheduleitemname">Site Name: </span></div>
                                                    <div><span className="smk-scheduleitemname">Checked-In: </span></div>
                                                    <div><span className="smk-scheduleitemname">Checked-out: </span></div>
                                                </div>
                                                <div style={ { textAlign: 'right', display: 'block' } }>
                                                    <div>
                                                        <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                            { dataset?.client?.name }</span>
                                                    </div>
                                                    <div>
                                                        <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                            { dataset?.site?.siteName }</span>
                                                    </div>
                                                    <div>
                                                        <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                            { moment(dataset.checkInTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                            { moment(dataset.checkOutTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid.Item>
                                        {/* <Grid.Item>
                                            <div className="smk-scheduleitemnamediv smk-scheduleitemnamediv_right">
                                                <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                    {dataset.date}</span></div>
                                            <div className="smk-scheduleitemnamediv smk-scheduleitemnamediv_right">
                                                <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                    {dataset.checkin_time}</span></div>
                                            <div className="smk-scheduleitemnamediv smk-scheduleitemnamediv_right">
                                                <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                    {dataset.checkout_time}</span></div>
                                        </Grid.Item> */}
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid.Item>
            </Grid>
        </div>
    );
};

export default StaffCompleted;