/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, createContext, useEffect, useReducer } from 'react';

type State = {
    user: any;
    business: any | null;
    token: any | null;
};

type Action = {
    type: string;
    payload: {
        user?: any;
        business?: any;
        token?: any;
    };
};

type Dispatch = (action: Action) => void;

type ContextType = {
    state: State;
    dispatch: Dispatch;
};

export const AuthContext = createContext<ContextType | undefined>(undefined);

export const authReducer = (state: State, action: Action) => {
    switch (action.type) {
    case 'LOGIN':
        return {
            user: action.payload.user,
            business: action.payload.business || null,
            token: action.payload.token || null,
        };
    case 'LOGOUT':
        return {
            user: null,
            business: null,
            token: null,
        };
    default:
        return state;
    }
};

type AuthContextProviderProps = {
    children: ReactNode;
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        business: null,
        token: null,
    });

    useEffect(() => {
        const userData = localStorage.getItem('userData');
        if (userData !== null) {
            const user = JSON.parse(userData);
            dispatch({ type: 'LOGIN', payload: user });
        }
    }, []);

    return (
        <AuthContext.Provider value= { { state, dispatch }}>
            { children }
        </AuthContext.Provider>
    );
};
