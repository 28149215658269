import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import reportWebVitals from './reportWebVitals';
import serviceWorkerDev from './serviceWorkerDev';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <AuthContextProvider>
        <App />
    </AuthContextProvider>,
);


reportWebVitals();
serviceWorkerDev();