import React from 'react';
import Header from '../../../header/header';
// import Navbar from '../../../navbar/navbar';
import GoBack from '../goBack';
import { useLocation } from 'react-router';
import NotificationStatus from './notificationStatus';

const NotificationView = () => {
    const notificationData = useLocation();
    console.log(notificationData.state.notifyData);
    const dataSet = notificationData.state.notifyData;
    return (
        <div className="smk-maindiv">
            <div>
                <Header />
            </div>
            <div className="smk-detailsdiv" style={{ paddingTop: '100px', height: '77vh' }}>
                <div className="smk-staffmain">
                    <GoBack />
                    <div className="smk-titlecontainer">
                        <span className="smk-profilename">Notifications</span>
                    </div>
                    <div>
                        <div className="smk-scheduleitemcheck">
                            <div className="smk-schedul-details">
                                <p>{dataSet.notification_title}</p>
                                <div className="smk-notificationdate">
                                    <NotificationStatus type={dataSet.notification_status} />
                                </div>
                                <div className="smk-notificationdetailscontainer">
                                    <div className="smk-notificationdetailsright">
                                        <p className="smk-detailstag">{dataSet.notification_description}</p>
                                    </div>
                                </div>
                                <div className="smk-notificationdate">
                                    <p className="smk-detailstag">
                                        {dataSet.notification_date}</p>
                                </div>
                                <div className="smk-notificationdate">
                                    <p className="smk-detailstag">{dataSet.notification_time}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div>
                <Navbar />
            </div> */}
        </div>
    );
};

export default NotificationView;