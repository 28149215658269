import React from 'react';
import Images from '../../assets/images/image';
import { Image, Modal, Popover } from 'antd-mobile';
import { Action } from 'antd-mobile/es/components/popover';
import { GoSignOut } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { useLogout } from '../../context/useLogout';
import ModalContent from '../staff/components/modalContent';
import { UserCircleOutline } from 'antd-mobile-icons';

const Header = () => {
    const navigate = useNavigate();
    const { logout } = useLogout();

    const handleConfirm = async () => {
        logout();
        navigate('/login');
    };
    const handleHome = async () => {
        navigate('/home');
    };

    const handleSignOut = () => {
        Modal.confirm({
            content: <ModalContent message='Are you sure want to sign out?' />,
            confirmText: 'Yes',
            cancelText: 'No',
            className: 'smk-modal',
            onConfirm: handleConfirm,
        });
    };
    const actions: Action[] = [
        {
            key: 'profile', text: <div className="smk-profileItem" onClick={ () => { navigate('/staff/profile'); } }>
                <UserCircleOutline
                    className='smk-headerpopover-icon'
                />Profile</div>,
        },
        {
            key: 'signout', text: <div className="smk-profileItem" onClick={ () => { handleSignOut(); } }>
                <GoSignOut
                    className='smk-headerpopover-icon'
                />Sign Out</div>,
        },
    ];
    return (
        <div className="smk-header">
            <div className="smk-headerimagediv">
                <Image
                    src={ Images.logo }
                    className='smk-header-logo'
                    alt='logo'
                    onClick={handleHome}
                    style={ { objectFit: 'cover', width: '100%', cursor: 'pointer' } }
                />
            </div>
            <div className="smk-headerdropdown-container">
                <Popover.Menu
                    actions={ actions }
                    placement='bottom-start'
                    trigger='click'
                    className='smk-headerpopover'
                >
                    <div style={ { display: 'flex' } }>
                        <div
                            className="smk-profile-image-container"
                        >
                            <Image src={ Images.user } style={ { objectFit: 'cover' } } />
                        </div>
                        <div className="smk-dropdown">
                            <Image src={ Images.dropDown } width='50%' />
                        </div>
                    </div>

                </Popover.Menu>
            </div>
        </div>
    );
};

export default Header;