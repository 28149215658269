import { Image } from 'antd-mobile';
import React from 'react';
import Images from '../../../assets/images/image';
import { useNavigate } from 'react-router-dom';

const GoBack = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <div className="smk-gobackcontainer" onClick={goBack}>
            <Image src={Images.leftArrow} width={'6%'} /><span className="smk-goacktitle">Go back</span>
        </div>
    );
};

export default GoBack;
