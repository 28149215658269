import { Select } from 'antd';
import { Button, Form, Modal, TextArea } from 'antd-mobile';
import apiClient from '../../../../utils/apiClient';
import ModalSuccess from '../modalSuccess';

type incidentProps = {
    jobId: number,
    visible:boolean,
    onSubmit:()=> void
}

const IncidentFrom = (props: incidentProps) => {
    const { jobId, visible, onSubmit } = props;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = async (values: any) => {
        try {
            const { reason, description } = values;
            const incidentDeatils = {
                incidentType: reason,
                incidentDescription: description,
            };
            const response = await apiClient.put(`/jobs/${jobId}/jobIncident`, incidentDeatils);
            if (response.status == 200) {
                Modal.alert({
                    content: <ModalSuccess message={'You have reported incident successfully.'} />,
                    closeOnMaskClick: true,
                    confirmText: 'Ok',
                    className: 'smk-modal smk-modal_alert',
                });
                onSubmit();
            }
        } catch (error) {
            console.log('Validation error:', error);
        }
    };

    return (
        <div className="smk-unablediv">
            { visible && <Form
                className="smk-form"
                layout="vertical"
                onFinish={ handleSubmit }
            >
                <Form.Item
                    name='reason'
                    label="Incident type"
                    style={ { backgroundColor: '$background-color' } }
                    rules={ [{ type: 'string', required: true, message: 'Please select your reason here!' }] }
                    className="smk-form-input"
                >
                    <Select
                        defaultValue="N/A"
                        style={ { width: '100%' } }
                        options={ [
                            { value: 'Vandalism', label: 'Vandalism' },
                            { value: 'Injury', label: 'Injury' },
                            { value: 'Other', label: 'Other' },
                        ] }
                    />
                </Form.Item>
                <Form.Item
                    name='description'
                    label="Incident"
                    style={ { backgroundColor: '$background-color' } }
                    rules={ [{ type: 'string', required: true, message: 'Please input your incident here!' }] }
                    className="smk-form-input"
                >
                    <TextArea
                        className="smk-textarea"
                        placeholder='Enter your incident'
                    />
                </Form.Item>
                <div
                    className="smk-editbutton-container smk-editbutton-container-incident"
                    style={ { paddingTop: '10px', justifyContent: 'flex-end' } }
                >
                    <Button className="smk-button smk-button-reject" type="submit" onClick={ handleSubmit }>
                        Submit
                    </Button>
                </div>
            </Form> }
        </div>
    );
};

export default IncidentFrom;