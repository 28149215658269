import { Image } from 'antd-mobile';
import Images from '../../../assets/images/image';
import React from 'react';

interface ModalProps {
    message: string;
};

const ModalContent = ({ message }: ModalProps) => {
    return (
        <div className="smk-modalcontent">
            <div className="smk-warningimagecontainer">
                <Image src={Images.warning} />
                <span className="smk-modaldetail">
                    {message}
                </span>
            </div>
        </div>
    );
};

export default ModalContent;