import { AutoCenter, Button, Form, Grid, Image, Input, Modal, NumberKeyboard, PasscodeInput } from 'antd-mobile';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Images from '../../assets/images/image';
import Footer from '../footer/footer';
import ModalError from '../staff/components/modalError';
import { useLogin } from './action';

const Login = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { login } = useLogin();
    const inputRef = useRef<HTMLInputElement>(null!);
    const [value, setValue] = useState('');
    const [loading, setloading] = useState(false);
    // const handleEmail = () => {
    //     navigate('/forgetpassword');
    // };

    const handleChange = (val: any) => {
        setValue(val);
        if (val.length === 4) {
            inputRef.current.blur();
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFormSubmit = async (values: any) => {
        const { pin, username } = values;
        setloading(true);
        try {
            // const email = localStorage.getItem('email');
            await login(username, pin);
            setloading(false);
            navigate('/home');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {

            const errorMsg = error.response?.data?.message || error.message;
            console.log(errorMsg);
            if (errorMsg) {
                if (errorMsg === 'User not found') {
                    Modal.alert({
                        content: <ModalError message='User not found.' />,
                        closeOnMaskClick: true,
                        confirmText: 'Ok',
                        className: 'smk-modal smk-modal_alert',
                    });
                } else {
                    Modal.alert({
                        content: <ModalError message='Username or password is incorrect.' />,
                        closeOnMaskClick: true,
                        confirmText: 'Ok',
                        className: 'smk-modal smk-modal_alert',
                    });
                }
            }
            setloading(false);
        }
    };
    return (
        <div className="smk-splashscreencontainer" style={ { height: '95vh' } }>
            <Grid columns={ 1 } gap={ 8 }>
                <div className="smk-splashimagecontainer smk-splashimagecontainer_logo">
                    <Image src={ Images.logo } className="smk-logoimage" />
                </div>
                <Grid.Item className="smk-logingrid">
                    <div>
                        <span className="smk-titlelogin">Login</span>
                    </div>
                    <Form
                        className="smk-form"
                        layout="vertical"
                        form={ form }
                        onFinish={ handleFormSubmit }
                    >
                        <Form.Item
                            name='username'
                            label='Username'
                            rules={ [{ type: 'string', required: true, message: 'Please input username here!' }] }
                            className="smk-form-input smk-form-input-login"
                            initialValue={ localStorage.getItem('email') }
                        >
                            <Input placeholder="Enter username" className="smk-input" />
                        </Form.Item>
                        <Form.Item
                            className="smk-form-input"
                            name='pin'
                            label='Pin'
                        // rules={ [{ type: 'number', required: true, message: 'Please input pin here!' }] }
                        >
                            <PasscodeInput
                                className="smk-inputpin"
                                onChange={ handleChange }
                                ref={ inputRef }
                                value={ value }
                                seperated length={ 4 } plain keyboard={ <NumberKeyboard /> } />
                        </Form.Item>
                        {/* <div className="smk-forgotpasswordcontainer">
                            <Button
                                className="smk-button-frogetpasswordlink"
                                onClick={() => {
                                    handleEmail();
                                }}
                            >
                                Forgot Pin
                            </Button>
                        </div> */}

                        <div className="smk-signinbuttoncontainer">
                            <AutoCenter>
                                <Button type="submit" className="smk-splashbutton" loading={ loading }
                                >
                                    Login
                                </Button>
                            </AutoCenter>
                        </div>

                        {/* <div className="smk-forgotpasswordcontainer">
                            <Button
                                className="smk-button-frogetpasswordlink"
                                onClick={() => {
                                    handleEmail();
                                }}
                            >
                                Forgot Pin
                            </Button>
                        </div> */}
                    </Form>
                </Grid.Item>
                <Grid.Item className="smk-splashfootercontainer">
                    <Footer />
                </Grid.Item>
            </Grid>
        </div>
    );
};

export default Login;