import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import apiClient from '../utils/apiClient';
import { IJob } from '../utils/types';
import { useAuthContext } from './useAuthContext';


interface JobContextValue {
    jobs: IJob[];
    job: IJob | null;
    scheduledJobs: IJob[] | null;
    inprogressJob: IJob[] | null;
    declinedJobs:IJob[] | null;
    addInprogressJob: (jobId: any, checkinDeatils: any) => void;
    completedJobs: IJob[] | null;
    addCompletedJob: (jobId: any, checkinDeatils: any) => void;
    cancelledJobs: IJob[] | null;
    // addCanceledJobs: (jobId: any, checkinDeatils: any) => void;
    addDeclinedJob: (jobId: any, checkinDeatils: any) => void;
    getOneJob:(jobId:any) =>void;
}

export const JobContext = createContext<JobContextValue>({
    jobs: [],
    job: null,
    scheduledJobs: null,
    inprogressJob: null,
    declinedJobs: null,
    addInprogressJob () {
        throw new Error('JobProvider not found');
    },
    completedJobs: null,
    addCompletedJob () {
        throw new Error('JobProvider not found');
    },
    cancelledJobs: null,
    // addCanceledJobs () {
    //     throw new Error('JobProvider not found');
    // },
    addDeclinedJob () {
        throw new Error('JobProvider not found');
    },
    getOneJob () {
        throw new Error('JobProvider not found');
    },

});

type JobContextProviderProps = {
    children: ReactNode;
};

export const JobProvider = ({ children }: JobContextProviderProps) => {
    const [jobs, setJobs] = useState<IJob[]>([]);
    const [scheduledJobs, setScheduledJobs] = useState<IJob[]>([]);
    const [completedJobs, setCompletedJobs] = useState<IJob[]>([]);
    const [inprogressJob, setInprogressJobs] = useState<IJob[]>([]);
    const [cancelledJobs, setCancelledJobs] = useState<IJob[]>([]);
    const [declinedJobs, setDeclinedJobs] = useState<IJob[]>([]);
    const [job, setJob] = useState<IJob | null>(null);
    const { state } = useAuthContext();

    const { user } = state;
    useEffect(() => {
        if (user) {
            fetchJobs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (user) {
            setScheduledJobs(jobs.filter((job) => { return job?.jobStatus === 'Scheduled'; }));
            setCompletedJobs(jobs.filter((job) => { return job?.jobStatus === 'Completed'; }));
            setInprogressJobs(jobs.filter((job) => { return job?.jobStatus === 'In_Progress'; }));
            setCancelledJobs(jobs.filter((job) => { return job?.jobStatus === 'Cancelled'; }));
            setDeclinedJobs(jobs.filter((job) => { return job?.jobStatus === 'Declined'; }));
        }
    }, [jobs, user]);

    useEffect(() => {
        console.log('Complted Jobs', completedJobs);
    }, [completedJobs]);


    const fetchJobs = async () => {
        try {
            const response = await apiClient.get('/jobs/staffJob');
            setJobs(response.data);
            console.log(jobs);
        } catch (error) {
            console.error('Error fetching Job:', error);
        }
    };

    const addInprogressJob = async (jobId: any, checkinDeatils: any) => {
        try {
            const response = await apiClient.put(`/jobs/${jobId}/checkIn`, checkinDeatils);
            const updatedJob = response.data;

            await setJobs((prevJobs) => {
                const updatedJobs = prevJobs.map((job) => { return job.jobId === updatedJob.jobId ? updatedJob : job; },
                );
                return updatedJobs;
            });

            await setScheduledJobs((prevScheduledJobs) => {
                return prevScheduledJobs.filter((job) => { return job.jobId !== updatedJob.jobId; });
            },
            );

            await setInprogressJobs((prevInprogressJobs) => { return [...prevInprogressJobs, updatedJob]; });
        } catch (error) {
            console.error('Error adding Job:', error);
        }
    };

    const addCompletedJob = async (jobId: any, checkOutDeatils: any) => {
        try {
            const response = await apiClient.put(`/jobs/${jobId}/checkOut`, checkOutDeatils);
            const updatedJob = response.data;

            await setJobs((prevJobs) => {
                const updatedJobs = prevJobs.map((job) => { return job.jobId === updatedJob.jobId ? updatedJob : job; },
                );
                return updatedJobs;
            });

            await setInprogressJobs((prevInprogressJobs) => {
                return prevInprogressJobs.filter((job) => { return job.jobId !== updatedJob.jobId; });
            },
            );

            await setCompletedJobs((prevCompletedJobs) => { return [...prevCompletedJobs, updatedJob]; });
        } catch (error) {
            console.error('Error adding Job:', error);
        }
    };
    const addDeclinedJob = async (jobId: any, rejectedDetails: any) => {
        try {
            const response = await apiClient.put(`/jobs/${jobId}/reject`, rejectedDetails);
            const updatedJob = response.data;

            await setJobs((prevJobs) => {
                const updatedJobs = prevJobs.map((job) => { return job.jobId === updatedJob.jobId ? updatedJob : job; },
                );
                return updatedJobs;
            });

            await setScheduledJobs((prevScheduledJobs) => {
                return prevScheduledJobs.filter((job) => { return job.jobId !== updatedJob.jobId; });
            },
            );

            await setDeclinedJobs((prevDeclinedJobs) => { return [...prevDeclinedJobs, updatedJob]; });
        } catch (error) {
            console.error('Error adding Job:', error);
        }
    };


    // const addCompltedJob = async (id: number) => {
    //     try {
    //         await apiClient.delete(`/jobs/${id}`);
    //         setJobs((prevJobs) => { return prevJobs.filter((job) => { return job.jobId !== id; }); });
    //     } catch (error) {
    //         console.error('Error deleting task:', error);
    //     }
    // };

    // const addCanceledJobs = async (id: number, updatedJob: IJob) => {
    //     try {
    //         const response = await apiClient.put(`/jobs/${id}`, updatedJob);
    //         setJobs((prevJobs) => {
    //             return prevJobs.map((job) => { return (job.jobId === id ? response.data : job); });
    //         },
    //         );
    //     } catch (error) {
    //         console.error('Error updating Job:', error);
    //     }
    // };

    const getOneJob = async (id: number) => {
        try {
            const response = await apiClient.get(`/jobs/${id}`);
            setJob(response.data);
        } catch (error) {
            console.error('Error fetching task:', error);
        }
    };

    const setOneJob = (job: IJob | null) => {
        setJob(job);
    };

    const contextValue = useMemo<JobContextValue>(
        () => {
            return {
                jobs,
                job,
                scheduledJobs,
                inprogressJob,
                declinedJobs,
                addInprogressJob,
                completedJobs,
                addCompletedJob,
                cancelledJobs,
                addDeclinedJob,
                getOneJob,
                setOneJob,
            };
        },
        [jobs, job, scheduledJobs, inprogressJob, completedJobs, cancelledJobs, declinedJobs]);

    return (
        <JobContext.Provider value={ contextValue }>
            { children }
        </JobContext.Provider>
    );
};
