import { AutoCenter, Button, Form, Grid, Image, Input, Modal } from 'antd-mobile';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Images from '../../../assets/images/image';
import Footer from '../../footer/footer';
import ModalSuccess from '../../staff/components/modalSuccess';
import { baseUrl } from '../../../Constants';

const ForgetPasswordEmail = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const handleBack = () => {
        navigate('/');
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFormSubmit = async (values: any) => {
        try {
            const userData = { email: values.email };
            localStorage.setItem('email', values.email);
            const response = await axios.post(`${baseUrl}/users/forget`, userData);
            if (response.status === 200) {
                Modal.alert({
                    content: <ModalSuccess message={'Verify code sent successfully.'} />,
                    closeOnMaskClick: true,
                    confirmText: 'Ok',
                    className: 'smk-modal smk-modal_alert',
                });
                navigate('/verifycode');
            }

        } catch (error) {
            console.log('Validation error:', error);
        }
    };
    return (
        <div className="smk-splashscreencontainer" style={ { height: '95vh' } }>
            <Grid columns={ 1 } gap={ 8 }>
                <div className="smk-splashimagecontainer">
                    <Image src={ Images.forgetPassword } style={ { height: '80%', width: 'auto', objectFit: 'cover', padding: '10px' } } />
                </div>
                <Grid.Item
                    style={ { overflow: 'scroll', paddingTop: '20px', paddingBottom: '30px', paddingLeft: '30px', paddingRight: '30px' } }
                >
                    <div>
                        <span className="smk-titlelogin">Forgot Pin</span>
                    </div>
                    <Form
                        className="smk-form"
                        layout="vertical"
                        form={ form }
                        onFinish={ handleFormSubmit }
                    >
                        <Form.Item
                            className="smk-form-input"
                            name='email'
                            label="Email"
                            rules={ [{ type: 'email', required: true, message: 'Please input your email here!' }] }
                        >
                            <Input placeholder="Enter email" className="smk-input" type="email" />
                        </Form.Item>

                        <AutoCenter className="smk-submitbuttondiv smk-submitbuttondiv-forgot">
                            <Button className="smk-button" type="submit">
                                Submit
                            </Button>
                        </AutoCenter>
                        <AutoCenter>
                            <div className="smk-schedul-row">
                                <p className="smk-linktext">Back to</p>
                                <Button className="smk-button-link" onClick={ () => { return handleBack(); } }>
                                    Home page
                                </Button>
                            </div>
                        </AutoCenter>
                    </Form>
                </Grid.Item>
                <Grid.Item>
                    <AutoCenter>
                        <Footer />
                    </AutoCenter>
                </Grid.Item>
            </Grid>
        </div>
    );
};

export default ForgetPasswordEmail;
