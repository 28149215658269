import { Button, Form, Modal, NumberKeyboard, PasscodeInput } from 'antd-mobile';
import axios from 'axios';
import { useAuthContext } from '../../../../context/useAuthContext';
import ModalSuccess from '../modalSuccess';
import { useRef, useState } from 'react';
import { baseUrl } from '../../../../Constants';

type staffChnagePasswordProps = {
    onSuccess: () => void
}

const StaffChangePassword = (props: staffChnagePasswordProps) => {

    const { onSuccess } = props;
    const { state } = useAuthContext();
    const { user } = state;
    const inputRef = useRef<HTMLInputElement>(null!);
    const [value, setValue] = useState('');
    const handleChange = (val: any) => {
        setValue(val);
        if (val.length === 4) {
            inputRef.current.blur();
        }
    };

    const userResetPassword = async (email: string, password: string) => {
        const userData = {
            email,
            password,
        };
        try {
            const response = await axios.post(`${baseUrl}/users/reset`, userData);
            return response.data;
        } catch (error) {
            console.error('Error creating user:', error);
            throw error;
        }
    };

    const handleFormSubmit = async (values: any) => {
        const { password } = values;
        const email = user.email;
        try {
            if (email) {
                await userResetPassword(email, password);
            }
            Modal.alert({
                content: <ModalSuccess message='You have changed pin successfully.' />,
                closeOnMaskClick: true,
                confirmText: 'Ok',
                className: 'smk-modal smk-modal_alert',
            });
            onSuccess();
        } catch (error) {
            console.log('Validation error:', error);
        }
    };
    return (
        <div
            style={ { width: '90%', paddingLeft: '15px', marginRight: '10px' } }
            className="smk-unablediv smk-unablediv_profile">
            <div className="smk-profiletitlecontainer">
                <span className="smk-profilename smk-profilename_pin">Change your Pin</span>
            </div>
            <Form
                className="smk-form"
                layout="vertical"
                onFinish={ handleFormSubmit }
            >
                <Form.Item
                    className="smk-form-input"
                    name='password'
                    label="Current Pin"
                // rules={ [{ type: 'number', required: true, message: 'Please input your pin here!' }] }
                >
                    <PasscodeInput className="smk-inputpin smk-inputpin_profile"
                        onChange={handleChange}
                        ref={inputRef}
                        value={value}
                        style={ { marginBottom: '15px' } } seperated length={ 4 } plain keyboard={ <NumberKeyboard /> } />
                </Form.Item>
                <Form.Item
                    className="smk-form-input"
                    name='confirmpassword'
                    label="New Pin"
                // rules={ [{ type: 'number', required: true, message: 'Please confirm your pin here!' }] }
                >
                    <PasscodeInput className="smk-inputpin"
                        style={ { marginBottom: '15px' } }
                        onChange={handleChange}
                        ref={inputRef}
                        value={value}
                        seperated length={ 4 } plain keyboard={ <NumberKeyboard /> } />
                </Form.Item>
                <div className="smk-updatepincontainer">
                    <Button className="smk-button smk-button-reject" type="submit">
                        Update Pin
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default StaffChangePassword;