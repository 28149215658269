import { Grid } from 'antd-mobile';
import { LocationFill } from 'antd-mobile-icons';
import moment from 'moment';
import { IJob } from '../../../../utils/types';

interface ScheduleDataset {
    dataset: IJob;
}

const StaffScheduleJoCard = ({ dataset }: ScheduleDataset) => {
    return (
        <Grid columns={ 1 } gap={ 10 }>
            <Grid.Item>
                <div className="smk-scheduledisplaydiv">
                    {/* <div className="smk-scheduledisplaydivleft">
                        <Image src={ Images.Logo_image } width='70%' />
                    </div> */}
                    <div className="smk-scheduledisplaydivright">
                        <div className="smk-schedul-row">
                            <div className="smk-schedul-details">
                                <Grid columns={ 1 }>
                                    <Grid.Item>
                                        <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                                            <div style={ { textAlign: 'left', display: 'block' } }>
                                                <div><span className="smk-scheduleitemname">Client: </span></div>
                                                <div><span className="smk-scheduleitemname">Site Name: </span></div>
                                                <div><span className="smk-scheduleitemname">Directions</span></div>
                                                <div><span className="smk-scheduleitemname">From: </span></div>
                                                <div><span className="smk-scheduleitemname">To: </span></div>
                                            </div>
                                            <div style={ { textAlign: 'right', display: 'block' } }>
                                                <div>
                                                    <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                        { dataset.client?.name }</span>
                                                </div>
                                                <div>
                                                    <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                        { dataset.site?.siteName }</span>
                                                </div>
                                                <div>
                                                    <a href={ `${dataset?.site?.locationLink}` } target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='smk-button-link smk-button-link-login'>
                                                        <LocationFill />Click here
                                                    </a>
                                                </div>
                                                <div>
                                                    <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                        { moment(dataset.startTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                        { moment(dataset.endTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </Grid.Item>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid.Item>
        </Grid>
    );
};

export default StaffScheduleJoCard;