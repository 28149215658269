import { Grid, Image } from 'antd-mobile';
import Images from '../../../../assets/images/image';
import { IJob } from '../../../../utils/types';
import moment from 'moment';

interface ScheduleDataset {
    dataset: IJob;
}

const StaffDeclinedJobCard = ({ dataset }: ScheduleDataset) => {
    return (
        <Grid columns={ 1 } gap={ 10 }>
            <Grid.Item>
                <div className="smk-scheduledisplaydiv">
                    <div className="smk-scheduledisplaydivleft">
                        <Image src={ Images.Logo_image } width='70%' />
                    </div>
                    <div className="smk-scheduledisplaydivright">
                        <div className="smk-schedul-row">
                            <div className="smk-schedul-details">
                                <Grid columns={ 1 }>
                                    <Grid.Item>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ textAlign: 'left', display: 'block' }}>
                                                <div><span className="smk-scheduleitemname">Client: </span></div>
                                                <div><span className="smk-scheduleitemname">Site Name: </span></div>
                                                <div><span className="smk-scheduleitemname">From: </span></div>
                                                <div><span className="smk-scheduleitemname">To: </span></div>
                                                <div><span className="smk-scheduleitemname">Reason for Declined: </span></div>
                                            </div>
                                            <div style={{ textAlign: 'right', display: 'block' }}>
                                                <div>
                                                    <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                        { dataset.client?.name }</span>
                                                </div>
                                                <div>
                                                    <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                        { dataset.site?.siteName }</span>
                                                </div>
                                                <div>
                                                    <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                        { moment(dataset.startTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                        { moment(dataset.endTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                                        { dataset?.rejectedReason }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid.Item>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid.Item>
        </Grid>
    );
};

export default StaffDeclinedJobCard;