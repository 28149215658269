import {
    Button,
    Collapse,
    Form,
    Grid,
    Image,
    ImageUploadItem,
    ImageUploader,
    Modal,
    Space,
    SpinLoading,
    TextArea,
    Toast,
} from 'antd-mobile';
import { CameraOutline } from 'antd-mobile-icons';
import AWS from 'aws-sdk';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Images from '../../../../assets/images/image';
import { JobContext } from '../../../../context/jobContext';
import { mockUpload } from '../../../../utils/imageUpload';
import { ITask } from '../../../../utils/types';
import Header from '../../../header/header';
import IncidentFrom from '../forms/incidentForm';
import UnableForm from '../forms/unableForm';
import ModalContent from '../modalContent';
import ModalError from '../modalError';
import ModalSuccess from '../modalSuccess';

AWS.config.update({
    accessKeyId: 'AKIAZIGW4DC2PXRSU57J',
    secretAccessKey: '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',
    region: 'ap-southeast-2',
    signatureVersion: 'v4',
});

const s3 = new AWS.S3();
const uploadBlobToS3 = async (blobUrl: string) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    const fileName = `image_${Date.now()}`;
    const params = {
        Bucket: 'smikappbucket',
        Key: `logo/${fileName}`,
        Body: blob,
    };

    console.log('Image Uploading');
    try {
        const { Location } = await s3.upload(params).promise();
        console.log('Uploaded to S3:', Location);
        return Location;
    } catch (error) {
        console.error('Error uploading to S3:', error);
        throw error;
    }
};

const StaffScheduleView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const { addInprogressJob, getOneJob, job } = useContext(JobContext);
    let activeKey = 'inprogress';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fileList, setFileList] = useState<ImageUploadItem[] | undefined>([]);
    const [showIncidentForm, setShowIncidentForm] = useState(false);
    const [showUnableForm, setShowUnableForm] = useState(false);
    const [imageUrlList, setImageUrlList] = useState<string[]>([]);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const dataset = location.state.dataset;

    useEffect(() => {
        getOneJob(dataset.jobId);
    }, [dataset, getOneJob]);


    const handleConfirm = async () => {
        let checkInLocation = 'Not Available';

        if (navigator.geolocation) {
            try {
                const position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                checkInLocation = `https://www.google.com/maps?q=${position.coords.latitude},${position.coords.longitude}`;
            } catch (error) {
                console.error('Error getting location:', error);
            }
        }

        const currentTime = new Date();
        const timezoneOffset = currentTime.getTimezoneOffset();
        const currentLocalTime = new Date(currentTime.getTime() - timezoneOffset * 60 * 1000);
        const formattedTime = currentLocalTime.toISOString();
        const checkInNote = form.getFieldsValue();

        const checkinDeatils = {
            checkInTime: formattedTime,
            beforePhotos: imageUrlList,
            checkInNote: checkInNote.checkInNote,
            checkInLocation: checkInLocation,
        };

        addInprogressJob(dataset.jobId, checkinDeatils);
        await Modal.alert({
            content: <ModalSuccess message='The check-in process has been completed successfully.' />,
            closeOnMaskClick: true,
            confirmText: 'Ok',
            className: 'smk-modal smk-modal_alert',
        });
        navigate('/staff/inprogress/view', { state: { dataset } });
    };

    const handleAccept = async () => {
        await Modal.confirm({
            content: <ModalContent message='Are you sure you want to proceed with the check-in?' />,
            confirmText: 'Yes',
            cancelText: 'No',
            className: 'smk-modal',
            onConfirm: handleConfirm,
        });
    };
    const handleReject = async () => {
        if (showIncidentForm || showUnableForm) {
            activeKey = 'cancel';
            navigate('/home', { state: { activeKey } });
        } else {
            await Modal.alert({
                content: <ModalError message='Please provide a valid reason for the rejection.' />,
                closeOnMaskClick: true,
                confirmText: 'Ok',
                className: 'smk-modal smk-modal_alert',
            });
        }
        setShowUnableForm(!showIncidentForm);
    };
    const handleUnable = () => {
        setShowUnableForm(!showUnableForm);
    };

    const goBack = () => {
        activeKey = 'schedule';
        navigate('/home', { state: { activeKey } });
    };

    const handleDecline = () => {
        activeKey = 'decline';
        navigate('/home', { state: { activeKey } });
    };

    const handleImageUpload = async (items: ImageUploadItem[]) => {
        setLoading(true);
        const updatedImageUrlList: string[] = [...imageUrlList];
        for (const item of items) {
            const imageUrl = await uploadBlobToS3(item.url);
            console.log('Image Url', imageUrl);
            updatedImageUrlList.push(imageUrl);
        }
        setLoading(false);
        Toast.show({
            icon: 'success',
            content: 'Image uploaded',
        });
        setFileList(items);
        setImageUrlList(updatedImageUrlList);
    };

    useEffect(() => {
        console.log('Image Url List', imageUrlList);
    }, [imageUrlList]);


    return (
        <div className="smk-maindiv">
            <div>
                <Header />
            </div>
            <div className="smk-detailsdiv" style={ { paddingTop: '100px', height: '78vh' } }>
                <div className="smk-staffmain">
                    <div className="smk-gobackcontainer" onClick={ goBack }>
                        <Image src={ Images.leftArrow } width={ '6%' } /><span className="smk-goacktitle">Go back</span>
                    </div>
                    <div className="smk-titlecontainer">
                        <span className="smk-profilename">View Job</span>
                    </div>
                    <div>
                        <div>
                            <div className="smk-scheduleitemcheck">
                                <div className="smk-schedul-details">
                                    <Grid columns={ 2 }>
                                        <Grid.Item>
                                            <p><span className="smk-scheduleitemname">Date: </span></p>
                                            <p><span className="smk-scheduleitemname">Start time: </span></p>
                                            <p><span className="smk-scheduleitemname">End time: </span></p>
                                            <p><span className="smk-scheduleitemname">Frequency: </span></p>
                                        </Grid.Item>
                                        <Grid.Item>
                                            <p className="smk-detailstag">{ dataset.date.slice(0, 10) }</p>
                                            <p className="smk-detailstag">
                                                { moment(dataset?.startTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }
                                            </p>
                                            <p className="smk-detailstag">
                                                { moment(dataset?.endTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }
                                            </p>
                                            <p className="smk-detailstag">{ dataset.frequency }</p>
                                        </Grid.Item>
                                    </Grid>
                                    <Collapse className="smk-collapse_task">
                                        <Collapse.Panel key='title'
                                            style={ { paddingLeft: '0px' } }
                                            title={
                                                <div style={ { width: '100%' } }>
                                                    <span className="smk-scheduleitemname">Tasks list: </span>
                                                </div>
                                            }>
                                            { job && <div>
                                                <Space direction='vertical' block>
                                                    { job?.task?.map((task: ITask) => {
                                                        return <div className="smk-tasklist-checkbox smk-tasklist-checkbox-inprogress"
                                                            key={ task.taskId }>
                                                            <div className="smk-taskdiv">
                                                                <Collapse className="smk-collapse_notify">
                                                                    <Collapse.Panel key={ task.taskId.toString() }
                                                                        className="smk-collapse_panel"
                                                                        title={
                                                                            <div className="smk-collapsediv">
                                                                                <div className="smk-broomcontainer">
                                                                                    <Image src={ Images.alarm } />
                                                                                </div>
                                                                                <p className="smk-collapsetitle">{ task.task }</p>
                                                                            </div> }
                                                                    >
                                                                        <div className="smk-tasktitle">
                                                                            { task.task }
                                                                        </div>
                                                                        <div>
                                                                            { task.outcome }
                                                                        </div>
                                                                        <div className="smk-descriptiondiv">
                                                                            <div className="smk-desleft">
                                                                                { task.area }
                                                                            </div>
                                                                            {/* <div className="smk-desright">
                                                                    <ImageUploader
                                                                        key={ task.taskId }
                                                                        className="smk-imageupload"
                                                                        value={ fileList }
                                                                        onChange={ setFileList }
                                                                        upload={ mockUpload }
                                                                    >
                                                                        <CameraOutline className="smk-uploadicon" /> Upload
                                                                    </ImageUploader>
                                                                </div> */}
                                                                        </div>
                                                                    </Collapse.Panel>
                                                                </Collapse>
                                                            </div>
                                                        </div>;
                                                    }) }
                                                </Space>
                                            </div> }
                                        </Collapse.Panel>
                                    </Collapse>
                                    <div>
                                        { loading && <Space justify='center' align='center'
                                            style={ {
                                                width: '100%',
                                                position: 'absolute', top: '35vh',
                                                zIndex: '3',
                                            } }>
                                            <SpinLoading color='primary' />
                                        </Space> }
                                        <Form className="smk-form" layout="vertical" form={ form }>
                                            {/* <Form.Item
                                            name='photo'
                                            label={<label htmlFor="upload-input"
                                                className="smk-uploadicon upload-image-btn">
                                                <UploadOutline />
                                                Upload Images
                                            </label>}
                                            className="smk-form-input"
                                            style={{ paddingLeft: '0px', width: '100%' }}
                                        > */}
                                            <ImageUploader
                                                style={ { marginTop: '1em' } }
                                                className='smk-imageupload'
                                                value={ fileList }
                                                upload={ mockUpload }
                                                // capture={ true }
                                                multiple
                                                accept='image/*'
                                                maxCount={ 5 }
                                                // onDelete={}
                                                onChange={ (items) => { return handleImageUpload(items); } }
                                            >
                                                <div
                                                    style={ {
                                                        width: 80,
                                                        height: 80,
                                                        borderRadius: 40,
                                                        backgroundColor: '#f5f5f5',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        color: '#999999',
                                                    } }
                                                >
                                                    <CameraOutline style={ { fontSize: 32 } } />
                                                </div>
                                            </ImageUploader>
                                            {/* <div>
                                                <input
                                                    id="upload-input"
                                                    type="file"
                                                    multiple
                                                    onChange={handleImageUpload}
                                                    max={5}
                                                    style={{ display: 'none' }}
                                                />
                                            </div> */}
                                            {/* </Form.Item> */ }
                                            <Form.Item
                                                name='checkInNote'
                                                label="Checkin note(optional)"
                                                className="smk-form-input"
                                                style={ { paddingLeft: '0px', width: '100%' } }
                                            >
                                                <TextArea
                                                    value={ value }
                                                    onChange={ (val) => {
                                                        setValue(val);
                                                    } }
                                                    className="smk-textarea"
                                                    placeholder='Enter your checkin note'
                                                />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                    <div style={ { display: 'flex', fontSize: '14px' } }>
                                    </div>
                                    <div style={ { display: 'flex', fontSize: '14px' } }>
                                        <p className="smk-linktext">Is there any incident?&nbsp;&nbsp;</p>
                                        <Button
                                            className="smk-button-link smk-button-link-login"
                                            style={ { fontWeight: 'bold' } }
                                            onClick={ () => { return setShowIncidentForm(!showIncidentForm); } }
                                        >
                                            Report
                                        </Button>
                                    </div>
                                    { showIncidentForm && <div className="smk-incidentform">
                                        <IncidentFrom
                                            visible={ showIncidentForm } jobId={ dataset.jobId }
                                            onSubmit={ () => { return setShowIncidentForm(!showIncidentForm); } } />
                                    </div> }
                                    <div style={ { display: 'flex', fontSize: '14px' } }>
                                        <p className="smk-linktext">Unable to complete?&nbsp;&nbsp;</p>
                                        <Button
                                            className="smk-button-link smk-button-link-login"
                                            style={ { fontWeight: 'bold' } }
                                            onClick={ handleUnable }
                                        >
                                            Reason
                                        </Button>
                                    </div>
                                    { showUnableForm && <div className="smk-incidentform">
                                        <UnableForm jobId={ dataset.jobId }
                                            onSuccess={ handleDecline }
                                        /></div> }
                                </div>
                            </div>
                        </div>
                        <div className="smk-accept-buttoncontainer">
                            <Button className="smk-button smk-button-reject" onClick={ () => { handleReject(); } }>Reject</Button>
                            <Button disabled={ loading }
                                className="smk-button smk-button-edit" onClick={ () => { handleAccept(); } }>Check-in</Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div>
                <Navbar />
            </div> */}
        </div >
    );
};

export default StaffScheduleView;