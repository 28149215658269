import axios from 'axios';
import { useState } from 'react';
import { useAuthContext } from '../../context/useAuthContext';
import { baseUrl } from '../../Constants';

export const useLogin = () => {
    const [error] = useState<null | string>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { dispatch } = useAuthContext();

    const login = async (email: string, password: string) => {

        setIsLoading(true);
        const userData = {
            email, password, userType: 'staffMember',
        };

        const response = await axios.post(`${baseUrl}/users/login`, userData);
        if (response.status === 200) {
            localStorage.setItem('userData', JSON.stringify(response.data));
            dispatch({ type: 'LOGIN', payload: response.data });
            setIsLoading(false);
        }

    };
    return { login, isLoading, error };
};