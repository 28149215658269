// import logo from '../images/logo_main.png';
import logo from '../images/logo.png';
import user from '../images/user.png';
import dropDown from '../images/caret-down.png';
import warningRed from '../images/warning_red.png';
import success from '../images/checked.png';
import verifycode from '../images/code.jpg';
import resetpassword from '../images/resetpin.jpg';
import forgetPassword from '../images/forgetpassword.jpg';
import splash from '../images/splash.jpg';
import Logo_image from '../images/logo_image.png';
import leftArrow from '../images/left_arrow.png';
import tick from '../images/tickImage.jpg';
import warning from '../images/warning.png';
import userImage from '../images/user.png';
import alarm from '../images/alarm.png';

export default {
    logo,
    user,
    dropDown,
    warningRed,
    success,
    verifycode,
    resetpassword,
    forgetPassword,
    splash,
    Logo_image,
    leftArrow,
    tick,
    warning,
    userImage,
    alarm,
};