import { Image } from 'antd-mobile';
import Images from '../../../assets/images/image';
import React from 'react';

interface ModalProps {
    message: string;
};

const ModalSuccess = ({ message }: ModalProps) => {
    return (
        <div className="smk-warningimagecontainer">
            <Image src={Images.success} />
            <span className="smk-modaldetail">
                {message}
            </span>
        </div>
    );
};

export default ModalSuccess;