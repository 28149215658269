import { useNavigate } from 'react-router-dom';
import { IJob } from '../../../../utils/types';
import StaffScheduleJoCard from './staffScheduleJobCard';

interface ScheduleDataset {
    dataset: IJob;
}
const StaffScheduleJobs = ({ dataset }: ScheduleDataset) => {
    const navigate = useNavigate();
    const handleView = () => {
        navigate('/staff/schedule/view', { state: { dataset } });
    };
    return (
        <div className="smk-schedul-item" onClick={ () => { handleView(); } }>
            <StaffScheduleJoCard dataset={ dataset } />
        </div>
    );
};

export default StaffScheduleJobs;