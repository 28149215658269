import React from 'react';
import Header from '../../../header/header';
// import Navbar from '../../../navbar/navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Image } from 'antd-mobile';
import Images from '../../../../assets/images/image';

const StaffCanclledView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dataset = location.state.dataset;
    const activeKey = 'cancel';
    const goBack = () => {
        navigate('/home', { state: { activeKey } });
    };
    return (
        <div className="smk-maindiv">
            <div>
                <Header />
            </div>
            <div className="smk-detailsdiv" style={{ paddingTop: '100px', height: '76vh' }}>
                <div className="smk-staffmain">
                    <div className="smk-gobackcontainer" onClick={goBack}>
                        <Image src={Images.leftArrow} width={'6%'} /><span className="smk-goacktitle">Go back</span>
                    </div>
                    <div className="smk-titlecontainer">
                        <span className="smk-profilename">Cancelled Job</span>
                    </div>
                    <div>
                        <div className="smk-scheduleitemcheck">
                            <div className="smk-schedul-details">
                                <Grid columns={2}>
                                    <Grid.Item>
                                        <p><span className="smk-scheduleitemname">Date: </span></p>
                                        <p><span className="smk-scheduleitemname">Started time: </span></p>
                                        <p><span className="smk-scheduleitemname">Cancelled time: </span></p>
                                        <p><span className="smk-scheduleitemname">Incident: </span></p>
                                    </Grid.Item>
                                    <Grid.Item>
                                        <p className="smk-detailstag"><span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                            {dataset.date}</span></p>
                                        <p className="smk-detailstag"><span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                            {dataset.start_time}</span></p>
                                        <p className="smk-detailstag"><span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                            {dataset.cancelled_time}</span></p>
                                        <p className="smk-detailstag"><span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                            {dataset.incident}</span></p>
                                    </Grid.Item>
                                </Grid>
                                <div><p className="smk-detailstag">
                                    <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                        description about incident</span></p></div>
                                <p><span className="smk-scheduleitemname">Excuses: </span></p>
                                <div>
                                    <p className="smk-detailstag">
                                        <span className="smk-scheduleitemname smk-scheduleitemname_detail">
                                            {dataset.other_notes}</span></p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div>
                <Navbar />
            </div> */}
        </div>
    );
};

export default StaffCanclledView;