import { Button, Form, Image, Input, Modal } from 'antd-mobile';
import { useEffect, useState } from 'react';
import Images from '../../../../assets/images/image';
import { useAuthContext } from '../../../../context/useAuthContext';
import apiClient from '../../../../utils/apiClient';
import Header from '../../../header/header';
// import Navbar from '../../../navbar/navbar';
import ModalSuccess from '../modalSuccess';
import StaffChangePassword from './staffChangePassword';

const StaffProfile = () => {
    const [showResetForm, setShowResetForm] = useState(false);
    const [editState, setEditState] = useState(false);
    // const [location, setLocation] = useState<any>(null);
    const [loading, setloading] = useState(false);
    const { state, dispatch } = useAuthContext();
    const { user } = state;

    useEffect(() => {
        if (user?.staffmember) {
            setloading(true);
        }
    }, [user?.staffmember]);


    const handleSubmitForm = async (values: any) => {
        const { firstName, lastName, contactNo } = values;
        const userDataString = localStorage.getItem('userData');
        const userInputData = {
            firstName,
            lastName,
            contactNo,
        };
        if (userDataString) {
            const response = await apiClient.put(`/staffs/${user.userId}`, userInputData);
            if (response.status == 200) {
                const userData = JSON.parse(userDataString);
                userData.user.staffmember.firstName = firstName;
                userData.user.staffmember.lastName = lastName;
                userData.user.staffmember.contactNo = contactNo;
                localStorage.setItem('userData', JSON.stringify(userData));
                dispatch({ type: 'LOGIN', payload: userData });
                setEditState(false);
                Modal.alert({
                    content: <ModalSuccess message='You have successfully updated your profile.' />,
                    closeOnMaskClick: true,
                    confirmText: 'Ok',
                    className: 'smk-modal smk-modal_alert',
                });
            }
        }
    };

    const handleResetForm = () => {
        setShowResetForm(true);
    };

    // const getLocation = () => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 setLocation({
    //                     latitude: position.coords.latitude,
    //                     longitude: position.coords.longitude,
    //                 });
    //             },
    //             (error) => {
    //                 console.error('Error getting location:', error);
    //             },
    //         );
    //     } else {
    //         console.error('Geolocation is not supported by this browser.');
    //     }
    // };


    return (
        <div className="smk-maindiv">
            <div>
                <Header />
            </div>
            <div className="smk-detailsdiv" style={ { paddingTop: '100px', height: '76vh' } }>
                <div className="smk-staffmain" style={ { paddingBottom: '20px' } }>
                    <div className="smk-titlecontainer">
                        <span className="smk-profilename">Staff Profile</span>
                    </div>
                    <div className="smk-profilecontainer">
                        <div
                            className="smk-profile-image-container smk-profile-image-container-profile"
                        >
                            <div style={ { position: 'relative' } }>
                                <Image src={ Images.userImage } style={ { objectFit: 'cover' } } />
                                {/* <div className="smk-buttonedit"> */ }
                                {/* <ImageUploader
                                        value={ fileList }
                                        onChange={ setFileList }
                                        upload={ mockUpload }
                                    >
                                        <EditFill />
                                    </ImageUploader> */}
                                {/* </div> */ }
                            </div>
                        </div>
                    </div>
                    <div>
                        { loading ? <Form
                            className="smk-form"
                            layout="vertical"
                            onFinish={ handleSubmitForm }
                            initialValues={ {
                                firstName: user?.staffmember.firstName,
                                lastName: user?.staffmember.lastName,
                                contactNo: user?.staffmember.contactNo,
                                email: user?.staffmember.email,
                            } }
                        >
                            <div className="smk-formInput">
                                <Form.Item
                                    className="smk-form-input"
                                    name='firstName'
                                    label="First Name"
                                    rules={ [{ type: 'string', required: true, message: 'Please input your first name here!' }] }
                                >
                                    <Input placeholder="Enter First Name" className="smk-input" disabled={ !editState } />
                                </Form.Item>
                            </div>
                            <div className="smk-formInput">
                                <Form.Item
                                    className="smk-form-input"
                                    name='lastName'
                                    label="Last Name"
                                    rules={ [{ type: 'string', required: true, message: 'Please input your last name here!' }] }
                                >
                                    <Input placeholder="Enter last name" className="smk-input" disabled={ !editState } />
                                </Form.Item>
                            </div>
                            <div className="smk-formInput">
                                <Form.Item
                                    className="smk-form-input"
                                    name='contactNo'
                                    label="Contact number"
                                    rules={ [{ type: 'string', required: true, message: 'Please input contact number here!' }] }
                                >
                                    <Input placeholder="Enter contact number" className="smk-input" disabled={ !editState } />
                                </Form.Item>
                            </div>
                            <div className="smk-formInput">
                                <Form.Item
                                    className="smk-form-input"
                                    name='email'
                                    label="Email"
                                    rules={ [{ type: 'email', required: true, message: 'Please input your email here!' }] }
                                >
                                    <Input placeholder="Enter email" className="smk-input" disabled />
                                </Form.Item>
                            </div>
                            {/* { location && (
                                <div>
                                    Latitude: { location.latitude }<br />
                                    Longitude: { location.longitude }
                                </div>
                            ) } */}
                            <div className="smk-editbutton-container">
                                {/* <Button
                                    className="smk-button smk-button-reject"
                                    onClick={ getLocation }
                                >
                                    Get Location
                                </Button> */}
                                <Button
                                    className="smk-button smk-button-reject"
                                    onClick={ handleResetForm }
                                >
                                    Change Pin
                                </Button>
                                { !editState &&
                                    <Button className="smk-button smk-button-edit" onClick={ () => { return setEditState(true); } }>
                                        Edit profile
                                    </Button> }
                                { editState && <Button className="smk-button smk-button-edit" type="submit">
                                    Save Changes
                                </Button> }
                            </div>
                        </Form> : <h1>Loading</h1> }
                        { showResetForm && <div className="smk-updatepin">
                            <StaffChangePassword onSuccess={ () => { return setShowResetForm(false); } } /></div> }
                    </div>
                </div>
            </div >
            {/* <div>
                <Navbar />
            </div> */}
        </div >
    );
};

export default StaffProfile;