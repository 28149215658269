import React, { useEffect, useState } from 'react';
import { Button } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

const Week = () => {
    const currentDate = new Date();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState<Date | null>(currentDate);
    const [currentWeekStartDate, setCurrentWeekStartDate] = useState<Date>(getWeekStartDate(currentDate));

    function getWeekStartDate (date: Date): Date {
        const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
        return startDate;
    }

    function getWeekEndDate (startDate: Date): Date {
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6);
        return endDate;
    }

    const goToPreviousWeek = () => {
        const previousWeekStartDate = new Date(currentWeekStartDate);
        previousWeekStartDate.setDate(previousWeekStartDate.getDate() - 7);
        setCurrentWeekStartDate(previousWeekStartDate);
    };

    const goToNextWeek = () => {
        const nextWeekStartDate = new Date(currentWeekStartDate);
        nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 7);
        setCurrentWeekStartDate(nextWeekStartDate);
    };

    useEffect(() => {
        if (selectedDate) {
            handleClick(selectedDate);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);


    const handleClick = (clickedDate: Date) => {
        setSelectedDate(clickedDate);
        navigate('/home', { state: { clickedDate } });
    };

    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const daysInWeek: Date[] = [];
    const startDate = getWeekStartDate(currentWeekStartDate);
    const endDate = getWeekEndDate(startDate);

    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        daysInWeek.push(new Date(date));
    }

    return (
        <div className="smk-datecell">
            <div className="week-navigation">
                <Button className="smk-button smk-button_date" onClick={goToPreviousWeek}>&lt;</Button>
            </div>
            { daysInWeek.map((day, index) => {
                const isCurrentDate = day.toDateString() === currentDate.toDateString();
                const isSelectedDate = selectedDate !== null && day.toDateString() === selectedDate.toDateString();
                const dayName = dayNames[day.getDay()];
                const dateNumber = day.getDate();
                return (
                    <div
                        key={ index }
                        className={ `date ${isSelectedDate ? 'selected-date' : ''}` }
                        onClick={ () => { handleClick(day); } }
                    >
                        <div className={ `day ${isCurrentDate && !isSelectedDate ? '' : ''}` }>{ dayName }</div>
                        <div className="date-number">{ dateNumber }</div>
                    </div>
                );
            })}
            <div className="week-navigation">
                <Button className="smk-button smk-button_date" onClick={goToNextWeek}>&gt;</Button>
            </div>
        </div>
    );
};

export default Week;