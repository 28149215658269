import { Button, Checkbox, Collapse, Form, Grid, Image, ImageUploadItem, 
    ImageUploader, Modal, Space, SpinLoading, TextArea, Toast } from 'antd-mobile';
import AWS from 'aws-sdk';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Images from '../../../../assets/images/image';
import { JobContext } from '../../../../context/jobContext';
import { Task } from '../../../../utils/types';
import Header from '../../../header/header';
// import Navbar from '../../../navbar/navbar';
import { CameraOutline } from 'antd-mobile-icons';
import { mockUpload } from '../../../../utils/imageUpload';
import ModalContent from '../modalContent';
import ModalSuccess from '../modalSuccess';

AWS.config.update({
    accessKeyId: 'AKIAZIGW4DC2PXRSU57J',
    secretAccessKey: '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',
    region: 'ap-southeast-2',
    signatureVersion: 'v4',
});

const s3 = new AWS.S3();
const uploadBlobToS3 = async (blobUrl: string) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    const fileName = `image_${Date.now()}`;
    const params = {
        Bucket: 'smikappbucket',
        Key: `logo/${fileName}`,
        Body: blob,
    };

    console.log('Image Uploading');
    try {
        const { Location } = await s3.upload(params).promise();
        console.log('Uploaded to S3:', Location);
        return Location;
    } catch (error) {
        console.error('Error uploading to S3:', error);
        throw error;
    }
};

const InProgressCheckIn = () => {
    const location = useLocation();
    const dataset = location.state.dataset;
    const { addCompletedJob, getOneJob, job } = useContext(JobContext);
    const [value, setValue] = useState('');
    let activeKey = 'complete';
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fileList, setFileList] = useState<ImageUploadItem[]>([]);
    const [form] = Form.useForm();
    const [imageUrlList, setImageUrlList] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    const goBack = () => {
        activeKey = 'inprogress';
        navigate('/home', { state: { activeKey } });
    };

    useEffect(() => {
        getOneJob(dataset.jobId);
    }, [dataset, getOneJob]);

    const handleConfirm = async () => {
        let checkOutLocation = 'Not Available';

        if (navigator.geolocation) {
            try {
                const position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                checkOutLocation = `https://www.google.com/maps?q=${position.coords.latitude},${position.coords.longitude}`;
            } catch (error) {
                console.error('Error getting location:', error);
            }
        }

        const currentTime = new Date();
        const timezoneOffset = currentTime.getTimezoneOffset();
        const currentLocalTime = new Date(currentTime.getTime() - timezoneOffset * 60 * 1000);
        const formattedTime = currentLocalTime.toISOString();
        const checkOutNote = form.getFieldsValue();
        const checkOutDeatils = {
            checkOutTime: formattedTime,
            afterPhotos: imageUrlList,
            checkOutNote: checkOutNote.checkOutNote,
            checkOutLocation: checkOutLocation,
        };
        addCompletedJob(dataset.jobId, checkOutDeatils);
        Modal.alert({
            content: <ModalSuccess message={ 'The check-out process has been completed successfully.' } />,
            confirmText: 'Ok',
            closeOnMaskClick: true,
            className: 'smk-modal smk-modal_alert',
        });
        navigate('/home', { state: { activeKey } });

    };

    const handleCheckout = async () => {
        Modal.confirm({
            content: <ModalContent message='Are you sure you want to proceed with the check-out?' />,
            confirmText: 'Yes',
            cancelText: 'No',
            className: 'smk-modal',
            onConfirm: handleConfirm,
        });

    };

    const handleImageUpload = async (items: ImageUploadItem[]) => {
        setLoading(true);
        const updatedImageUrlList: string[] = [...imageUrlList];
        for (const item of items) {
            const imageUrl = await uploadBlobToS3(item.url);
            console.log('Image Url', imageUrl);
            updatedImageUrlList.push(imageUrl);
        }
        setLoading(false);
        Toast.show({
            icon: 'success',
            content: 'Image uploaded',
        });
        setFileList(items);
        setImageUrlList(updatedImageUrlList);
    };

    return (
        <div className="smk-maindiv">
            <div>
                <Header />
            </div>
            <div className="smk-detailsdiv" style={ { paddingTop: '100px', height: '76vh' } }>
                <div className="smk-staffmain">
                    <div className="smk-gobackcontainer" onClick={ goBack }>
                        <Image src={ Images.leftArrow } width={ '6%' } /><span className="smk-goacktitle">Go back</span>
                    </div>
                    <div className="smk-titlecontainer">
                        <span className="smk-profilename">Job In-Progress</span>
                    </div>
                    <div>
                        <div className="smk-scheduleitemcheck">
                            <div className="smk-schedul-details">
                                <Grid columns={ 2 }>
                                    <Grid.Item>
                                        <p><span className="smk-scheduleitemname">Date: </span></p>
                                        <p><span className="smk-scheduleitemname">Start time: </span></p>
                                        <p><span className="smk-scheduleitemname">Checked-in time: </span></p>
                                        <p><span className="smk-scheduleitemname">End time: </span></p>
                                    </Grid.Item>
                                    <Grid.Item>
                                        <p className="smk-detailstag">{ dataset.date.slice(0, 10) }</p>
                                        <p className="smk-detailstag">
                                            { moment(dataset?.startTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</p>
                                        <p className="smk-detailstag">
                                            { moment(dataset?.checkInTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</p>
                                        <p className="smk-detailstag">
                                            { moment(dataset?.endTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</p>
                                    </Grid.Item>
                                </Grid>
                                <div className="smk-taskdiv">
                                    <Collapse className="smk-collapse_task">
                                        <Collapse.Panel key='title' style={ { paddingLeft: '0px' } }
                                            title={
                                                <p><span className="smk-scheduleitemname">Tasks list: </span></p>
                                            }>
                                            <div>
                                                {job && <Space direction='vertical' block>
                                                    { job.task?.map((tasks: Task, index: number) => {
                                                        return <div
                                                            className="smk-tasklist-checkbox smk-tasklist-checkbox-inprogress"
                                                            key={ index }
                                                        >
                                                            <div className="smk-taskdiv">
                                                                <Collapse className="smk-collapse_task">
                                                                    <Collapse.Panel key='1' title={
                                                                        <div className="smk-collapsediv">
                                                                            <div className="smk-broomcontainer">
                                                                                <Checkbox></Checkbox>
                                                                            </div>
                                                                            <p className="smk-collapsetitle">{ tasks.task }</p>
                                                                        </div>
                                                                    }>
                                                                        <div className="smk-tasktitle">
                                                                            { tasks.task }
                                                                        </div>
                                                                        <div>
                                                                            { tasks.outcome }
                                                                        </div>
                                                                        <div className="smk-descriptiondiv">
                                                                            <div className="smk-desleft">
                                                                                { tasks.area }
                                                                            </div>
                                                                        </div>
                                                                    </Collapse.Panel>
                                                                </Collapse>
                                                            </div>
                                                        </div>;
                                                    }) }
                                                </Space>}
                                            </div>
                                        </Collapse.Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        { loading && <Space justify='center' align='center'
                            style={ {
                                width: '100%',
                                position: 'absolute', top: '35vh',
                                zIndex: '3',
                            } }>
                            <SpinLoading color='primary' />
                        </Space> }
                        <Form className="smk-form" layout="vertical" form={ form }>
                            <ImageUploader
                                style={ { marginTop: '1em' } }
                                className='smk-imageupload'
                                value={ fileList }
                                upload={ mockUpload }
                                // capture={ true }
                                multiple
                                accept='image/*'
                                maxCount={ 5 }
                                // onDelete={}
                                onChange={ (items) => { return handleImageUpload(items); } }
                            >
                                <div
                                    style={ {
                                        width: 80,
                                        height: 80,
                                        borderRadius: 40,
                                        backgroundColor: '#f5f5f5',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#999999',
                                    } }
                                >
                                    <CameraOutline style={ { fontSize: 32 } } />
                                </div>
                            </ImageUploader>
                            <Form.Item
                                name='checkOutNote'
                                label="Checkout note(optional)"
                                className="smk-form-input"
                                style={ { paddingLeft: '20px', width: '100%' } }
                            >
                                <TextArea
                                    value={ value }
                                    onChange={ (val) => {
                                        setValue(val);
                                    } }
                                    className="smk-textarea"
                                    placeholder='Enter your checkout note'
                                />
                            </Form.Item>
                        </Form>
                    </div>
                    {/* <Form>
                        <Form.Item
                            name='photo'
                            label="Enter photographs after the job"
                            className="smk-form-input"
                            style={ { width: '90%' } }
                        >
                            <ImageUploader
                                className="smk-imageupload"
                                value={ fileList }
                                onChange={ setFileList }
                                upload={ mockUpload }
                                capture={ true }
                            >
                                <UploadOutline className="smk-uploadicon" /> Upload
                            </ImageUploader>
                        </Form.Item>
                    </Form> */}
                    {/* <div style={ { display: 'flex', fontSize: '14px', paddingLeft: '15px' } }>
                        <p className="smk-linktext">Is there any incident?&nbsp;&nbsp;</p>
                        <Button
                            className="smk-button-link smk-button-link-login"
                            style={ { fontWeight: 'bold' } }
                            onClick={ handleIncident }
                        >
                            Report
                        </Button>
                    </div> */}
                    {/* {showIncidentForm && <div className="smk-incidentform 
                    smk-incidentform_progress"><IncidentFrom jobId={}/></div>} */ }
                    {/* <div style={ { display: 'flex', fontSize: '14px', paddingLeft: '15px' } }>
                        <p className="smk-linktext">Unable to complete?&nbsp;&nbsp;</p>
                        <Button
                            className="smk-button-link smk-button-link-login"
                            style={ { fontWeight: 'bold' } }
                            onClick={ handleUnable }
                        >
                            Excuses(s)
                        </Button>
                    </div> */}
                    {/* { showUnableForm && <div className="smk-incidentform smk-incidentform_progress"><UnableForm /></div> } */ }
                    <div
                        className="smk-accept-buttoncontainer smk-accept-buttoncontainer_accept"
                        style={ { paddingTop: '15px', paddingBottom: '15px' } }
                    >
                        <Button disabled={loading} className="smk-button smk-button-edit" onClick={ handleCheckout }>Check-Out</Button>
                    </div>
                </div>
            </div>
            {/* <div>
                <Navbar />
            </div> */}
        </div>
    );
};

export default InProgressCheckIn;